<template>
  <div>
    <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage"
             v-on:close-dialog="closeDialog"/>
    <details-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog"
                  v-on:update-status="updateStatus" v-on:send-sale="sendSale" v-on:send-reversal="sendReversal"/>
    <v-container class="container--fluid grid-list-md text-center">
      <h1>Transactions</h1>
    </v-container>

    <v-card elevation="2" outlined shaped tile padding="20px">
      <v-row class="ma-1">
        <v-col cols="4">
          <v-menu v-model="fromDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                  offset-y max-width="290px" min-width="290px" data-cy="date-menu-from">
            <template v-slot:activator="{ on }">
              <v-text-field label="Date Created From" prepend-icon="mdi-calendar" readonly :value="fromDateDisp"
                            v-on="on" data-cy="date-menu-from"></v-text-field>
            </template>
            <v-date-picker locale="en-in" v-model="fromDateVal" no-title @input="fromDateMenu = false" :min="minDate"
                           :max="fromMax" data-cy="date-menu-from"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="4">
          <v-menu ref="menu" v-model="toDateMenu" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-to">
            <template v-slot:activator="{ on }">
              <v-text-field label="Date Created To" prepend-icon="mdi-calendar" readonly :value="toDateDisp" v-on="on"
                            data-cy="date-menu-to"></v-text-field>
            </template>
            <v-date-picker locale="en-in" v-model="toDateVal" no-title @input="toDateMenu = false" :max="maxDate"
                           :min="toMin" data-cy="date-menu-to">
              <v-btn text color="primary" @click="clearToDate()" data-cy="button-clear">Clear
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="d-flex" cols="4">
          <v-text-field class="px-4" label="Payconnect Reference #" single-line @change="searchPayConnectRef"
                        data-cy="module-search-payconnect-reference"></v-text-field>
        </v-col>
      </v-row>

      <v-row class="ma-1">
        <v-col class="d-flex" cols="4">
          <v-select class="px-4" :items="paymentStatus" label="Payment Status" @change="searchStatus"
                    data-cy="module-search-status"></v-select>
        </v-col>
        <v-col class="d-flex" cols="4">
          <v-text-field class="px-4" label="Merchant Reference #" single-line @change="searchMerchantRef"
                        data-cy="module-search-merchant-reference"></v-text-field>
        </v-col>
      </v-row>
      <v-card-actions elevation="2" class="container--fluid grid-list-md text-center">
        <v-row>
          <v-col cols="2">
            <v-btn dark medium color=success @click="queryDataV2()" data-cy="button-search">
              <v-icon dark left> mdi-magnify</v-icon>
              Search
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <v-data-table :headers="headers" :options.sync="options"
                  :loading="loading"
                  :items="displayReports"
                  :page="page"
                  :pageCount="numberOfPages"
                  :server-items-length="totalReports"
                  :footer-props="{'items-per-page-options': [10, 20, 50]}">
      <template v-slot:body="{ items }">
        <tbody>
        <tr :colspan="headers.length" v-for="item in items" :key="item.id">
          <td><span class="d-flex"> {{ item.payconnectReference }} </span></td>
          <td><span class="d-flex justify-end"> <pre>{{ item.amount }} </pre></span></td>
          <td><span class="d-flex"> {{ item.paymentStatus }} </span></td>
          <td><span class="d-flex justify-center"> {{ item.reasonCode }} </span></td>
          <td><span class="d-flex"> {{ item.merchantReferenceNo }} </span></td>
          <td><span class="d-flex"> {{ item.merchantName }} </span></td>
          <td><span class="d-flex justify-center"> {{ item.billerName }} </span></td>
          <td><span class="d-flex justify-center"> {{ item.dateCreated }} </span></td>
          <td>
            <span class="d-flex justify-center">
                <v-btn fab dark x-small color="green" class="mr-2" @click.stop="viewTransaction(item)">
                    <v-icon dark medium>mdi-magnify-plus</v-icon>
                </v-btn>
            </span>
          </td>
        </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
    mapGetters
} from 'vuex';
import TransactionServiceAPI from '@/api/TransactionServiceAPI.js'
import Loading from '@/views/components/Loading.vue'
import DetailsForm from '@/views/components/TransactionForm.vue'
import moment from 'moment';

export default {
    name: 'Transactions',
    components: {
        Loading,
        DetailsForm
    },
    computed: {
        ...mapGetters([
            'attributes',
            'authorities'
        ]),
        fromDateDisp() {
            this.toMin = moment().format(this.fromDateVal)
            return this.fromDateVal;
        },
        toDateDisp() {
            this.fromMax = moment().format(this.toDateVal)
            return this.toDateVal;
        },
    },
    data() {
        return {
            disableCsvExportButton: true,
            paymentStatus: ['', 'PAID', 'PENDING', 'POSTED', 'EXPIRED', 'STIP', 'FOR VALIDATION', 'DECLINED'],
            headers: [
                {
                    text: 'Reference Number',
                    value: 'payconnectReference',
                    sortable: false,
                },
                {
                    text: 'Amount',
                    value: 'amount',
                    sortable: false,
                    align: 'right',
                },
                {
                    text: 'Payment Status',
                    value: 'paymentStatus',
                    sortable: false,
                },
                {
                    text: 'Reason Code',
                    value: 'reasonCode',
                    sortable: false,
                },
                {
                    text: 'Merchant Ref. Num.',
                    value: 'merchantReferenceNo',
                    sortable: false,
                },
                {
                    text: 'Merchant',
                    value: 'merchantName',
                    sortable: false,
                },
                {
                    text: 'Biller',
                    value: 'billerName',
                    sortable: false,
                },
                {
                    text: 'Date Created',
                    value: 'dateCreated',
                    sortable: false,
                    align: 'center',
                },
                {
                    text: 'Actions',
                    value: 'controls',
                    sortable: false,
                },
            ],
            isNotMerchant: false,
            records: [],
            loading: true,
            selectedMerchant: {},
            selectedStatus: '',
            payConnectRef: '',
            merchantRef: '',
            page: 1,
            totalReports: 0,
            numberOfPages: 0,
            options: {
                page: 1,
                itemsPerPage: 10,
            },
            details: {
                title: '',
                item: {}
            },
            fromDateMenu: false,
            fromDateVal: "",
            toDateMenu: false,
            toDateVal: "",
            minDate: "1997-01-01",
            maxDate: "",
            fromMax: "",
            toMin: "",
            offset: 0,
            limit: 0,
            displayReports: [{}],
            remainderReports: [],
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            isShowDetails: false,
            showFormDialog: false,
            isHtml: false,

        }
    },
    async mounted() {
        this.loadingDialog = true;
        this.setDefaultDateRange();
        await this.setData();
        this.loadingDialog = false;
    },
    watch: {
        options: {
            handler() {
                this.queryDataV2();
            },
            deep: true,
        },
    },
    methods: {
        hasPermission(authority) {
            if (this.authorities.some(x => x.name === authority)) {
                return true
            }
            return false
        },
        async queryTransactionData() {
            this.loading = true;
            const parameters = {
                offset: this.options.page - 1,
                limit: this.options.itemsPerPage,
                merchantID: this.selectedMerchant ? this.selectedMerchant.merchantId ?? '' : '',
                payID: this.payConnectRef,
                merchantReferenceNo: this.merchantRef,
                paymentStatus: this.selectedStatus,
                dateFrom: this.fromDateVal,
                dateTo: this.toDateVal
            }
            const transactionList = await TransactionServiceAPI.filterSevenPayTransactions(parameters)
            this.totalReports = transactionList.totalRows;
            this.displayReports = transactionList.transactions;
            this.disableCsvExportButton = this.totalReports < 1;
            this.loading = false
        },
        //method to update the Merchant query
        searchStatus: async function (val) {
            this.selectedStatus = val
            // this.queryData(true)
        },
        //method to update the Merchant query
        searchMerchant: async function (val) {
            this.selectedMerchant = val
            // this.queryData(true)
        },
        searchPayConnectRef(val) {
            this.payConnectRef = val
            // this.queryData(true)
        },
        searchMerchantRef(val) {
            this.merchantRef = val
            // this.queryData(true)
        },
        setDefaultDateRange() {
            this.fromDateVal = moment().subtract(1, "day").format("yyyy-MM-DD");
            this.toDateVal = moment().format("yyyy-MM-DD");
            this.maxDate = moment().add(1, "days").format("yyyy-MM-DD")
            this.fromMax = moment().add(1, "days").format("yyyy-MM-DD")
            this.toMin = moment().format(this.fromDateVal)
        },
        clearToDate() {
            this.toDateVal = ''
            this.toDateMenu = false
        },
        closeDialog(type) {
            if (type === 'details') {
                this.details.title = ''
                this.details.item = {}
                this.isShowDetails = false
            } else if (type === 'CREATED' || type === 'UPDATE') {
                this.showFormDialog = false
            } else if (type === 'loading') {
                this.loadingDialog = false
                // this.queryData();
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            }
        },
        viewTransaction(transaction) {
            console.log(transaction)
            this.details.title = transaction.payconnectReference
            this.details.merchantId = transaction.merchantId
            this.details.status = transaction.paymentStatus
            this.details.isStipMerchant = transaction.isStipMerchant
            this.details.item = transaction
            this.isShowDetails = true
        },
    }
};
</script>
