import Vue from 'vue'
import router from '@/router/index';

export default {

    async filterSevenPayTransactions(parameters) {

        /** Use when CORS issue is resolved, directly communicate to server without proxy */
        let url = `${process.env.VUE_APP_CASHOUT_ADMIN_URL}/v1/transaction/search`;
        if (process.env.VUE_APP_IS_PROXY === 'true') {
            /** Use when CORS issue persists, and use proxy */
            url = 'switch/v1/transaction/search';
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
            },
        };
        const body = {
            dateFrom: parameters.dateFrom,
            dateTo: parameters.dateTo,
            merchantID: parameters.merchantID,
            merchantReferenceNo: parameters.merchantReferenceNo,
            payID: parameters.payID,
            paymentStatus: parameters.paymentStatus,
            pageNo: parameters.offset,
            pageSize: parameters.limit,
        };
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                return response.data;
            }
            if (response && response.data && response.data.message) {
                return {error: response.data.message};
            }
            return {error: 'Sorry. Error getting list of merchants.'};
        } catch (error) {
            console.log(error);
            return {error};
        }
    },

}